export interface DateSection {
    start: Date;
    end: Date;
}

export interface Employer {
    id: number;
    name: string;
    shortName: string;
}

export enum WorkDayType {
    PTO = 'pto',
    HalfPTO = 'half-pto',
    Holiday = 'holiday',
    SickLeave = 'sick-leave'
}
