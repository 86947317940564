interface I18n {
    end: string;
    start: string;
    deleteHours: string;
    deleteConfirmation: string;
    editHours: string;
    employerDoNotExist: string;
    day: string;
    time: string;
    totalTime: string;
    dailyEarnings: string;
    hourlyRate: string;
    type: string;
    payedTimeOff: string;
    holiday: string;
    cash: string;
    billingCycleEnd: string;
    billingCycle: string;
    more: string;
}

export const t: I18n = {
    billingCycleEnd: 'Koniec okresu rozliczeniowego',
    billingCycle: 'Okres rozliczeniowy',
    cash: 'Gotówka:',
    totalTime: 'Całkowity czas:',
    dailyEarnings: 'Średnio za dzień:',
    hourlyRate: 'Stawka',
    type: 'Typ',
    payedTimeOff: 'Urlop',
    holiday: 'Święto',
    time: 'Czas',
    day: 'Dzień',
    start: 'Start',
    employerDoNotExist: 'Pracodawca nie istnieje',
    editHours: 'Edytuj godziny pracy',
    deleteConfirmation: 'Czy an pewno chcesz usunąć wiersz',
    deleteHours: 'Usuwanie godzin pracy',
    end: 'Koniec',
    more: 'więcej...'
};
